/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

.sticky {
	position: sticky !important;
	position: -webkit-sticky !important;
	z-index: 5;
	top: 10px;
}

.fb-comments, .fb-comments span, .fb-comments iframe { width: 100% !important; }

.rsw-editor {
	min-height: 400px !important;
}

.mapmarker {
	font-size: 0.95rem;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-size: 16px 12px;
	@apply bg-clip-padding bg-no-repeat;
}

.highlight {
  background-color: yellow;
}

.wheel {
	width: 800px !important;
	height: 800px !important;
}